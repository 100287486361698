<template>
  <div class="working-transport main-block">
    <div class="main-block__head">
      <h1 class="main-block__title">Работа с транспортом</h1>
      <button class="main-block__btn default-btn flex">
        <b-icon class="mr-1" icon="plus" font-scale="2" />
        Назначить водителя
      </button>
    </div>
    <div class="maintenance__add flex">
      <p>Нет записей</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.maintenance__add {
  min-height: 265px;
  justify-content: center;
}
.main-block__btn {
  padding: 0px 9px 0 0;
}
</style>
